export function Home() {
  return (
    <div
      style={{
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img width={200} src='/logo.png' />
      <div style={{ fontSize: 20, fontWeight: 600 }}>관리자 페이지</div>
    </div>
  );
}
