import { useState } from 'react';
import PaymentResultInput from './PaymentResultInput';
export class OptionInput {
  constructor(
    public name: string,
    public price: number,
    public quantity: number
  ) {}
}
export default function PaymentResultOptionInput({
  optionInputs,
}: PaymentResultOptionInputProps) {
  const [optionName, setOptionName] = useState(optionInputs.name);
  const [optionPrice, setOptionPrice] = useState('');
  const [optionQuantity, setOptionQuantity] = useState('');
  const handleNameChange = (value: any) => {
    setOptionName(value);
    optionInputs.name = value;
  };
  const handlePriceChange = (value: any) => {
    setOptionPrice(value);
    optionInputs.price = parseInt(value);
  };
  const handleQuantityChange = (value: any) => {
    setOptionQuantity(value);
    optionInputs.quantity = parseInt(value);
  };
  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '16px',
        marginBottom: '20px',
        borderRadius: '10px',
      }}
    >
      <PaymentResultInput
        title="옵션명"
        type={0}
        value={optionName}
        onChange={handleNameChange}
      />
      <PaymentResultInput
        title="개당 가격"
        type={1}
        value={optionPrice}
        onChange={handlePriceChange}
      />
      <PaymentResultInput
        title="갯수"
        type={1}
        value={optionQuantity}
        onChange={handleQuantityChange}
      />
    </div>
  );
}
interface PaymentResultOptionInputProps {
  optionInputs: OptionInput;
}
