import axios from 'axios';

export const client = axios.create({
  baseURL: 'https://api.travelmarket.site',
  // baseURL: 'http://localhost:3001',
  // baseURL: 'http://192.168.2.57:3001',
});
// client.defaults.withCredentials = true;
export const fetcher = (url: string) =>
  client.get(url).then((response) => response.data);

client.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode =
      error.response?.status || error.response?.data?.statusCode;

    if (statusCode === 401) {
      alert('인증이 만료되었습니다.\n다시 로그인 해주세요.');
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      client.defaults.headers.common = {
        Authorization: '',
      };
      window.location.href = '/login';
    } else if (statusCode === 500) {
      alert('서버와의 연결이 원활하지 않습니다.\n잠시 후 다시 시도해주세요.');
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
