export const continent = [
  {
    value: '0',
    name: '전체',
  },
  {
    value: '1',
    name: '동북아시아',
  },
  {
    value: '2',
    name: '동남아시아',
  },
  {
    value: '3',
    name: '유럽',
  },

  {
    value: '4',
    name: '오세아니아',
  },
  {
    value: '5',
    name: '북아메리카',
  },
];

export const country = [
  {
    continent: '1',
    value: '1',
    name: '일본',
  },
  {
    continent: '1',
    value: '2',
    name: '대만',
  },
  {
    continent: '1',
    value: '3',
    name: '홍콩',
  },
  {
    continent: '1',
    value: '4',
    name: '중국',
  },
  {
    continent: '2',
    value: '1',
    name: '태국',
  },
  {
    continent: '2',
    value: '2',
    name: '베트남',
  },
  {
    continent: '2',
    value: '3',
    name: '싱가포르',
  },
  {
    continent: '2',
    value: '4',
    name: '필리핀',
  },
  {
    continent: '2',
    value: '5',
    name: '인도네시아',
  },
  {
    continent: '2',
    value: '6',
    name: '말레이시아',
  },
  {
    continent: '3',
    value: '0',
    name: '전체',
  },
  {
    continent: '3',
    value: '1',
    name: '영국',
  },
  {
    continent: '3',
    value: '2',
    name: '프랑스',
  },
  {
    continent: '3',
    value: '3',
    name: '스페인',
  },
  {
    continent: '3',
    value: '4',
    name: '독일',
  },
  {
    continent: '3',
    value: '5',
    name: '이탈리아',
  },
  {
    continent: '4',
    value: '1',
    name: '호주',
  },
  {
    continent: '5',
    value: '1',
    name: '미국',
  },
  {
    continent: '5',
    value: '2',
    name: '캐나다',
  },
];

export const city = [
  {
    continent: '1',
    country: '1',
    city: [
      {
        value: '0',
        name: '전체',
      },
      {
        value: '1',
        name: '도쿄',
      },
      {
        value: '2',
        name: '오사카',
      },
      {
        value: '3',
        name: '후쿠오카',
      },
      {
        value: '4',
        name: '교토',
      },
      {
        value: '5',
        name: '유후인',
      },
      {
        value: '7',
        name: '삿포로',
      },
      {
        value: '8',
        name: '벳푸',
      },
      {
        value: '9',
        name: '하코네',
      },
    ],
  },
  {
    continent: '1',
    country: '2',
    city: [
      { name: '전체', value: '0' },
      { name: '가오슝', value: '1' },
      { name: '신베이', value: '2' },
      { name: '타이난', value: '3' },
      { name: '타이베이', value: '4' },
      { name: '타이중', value: '5' },
      { name: '화련', value: '6' },
    ],
  },
  {
    continent: '1',
    country: '3',
    city: [
      { name: '전체', value: '0' },
      { name: '홍콩', value: '1' },
      { name: '라마도', value: '2' },
    ],
  },
  {
    continent: '1',
    country: '4',
    city: [
      { name: '전체', value: '0' },
      { name: '광저우', value: '1' },
      { name: '난징', value: '2' },
      { name: '베이징', value: '3' },
      { name: '상하이', value: '4' },
      { name: '샤먼', value: '5' },
      { name: '시안', value: '6' },
      { name: '칭다오', value: '7' },
      { name: '쿤밍', value: '8' },
      { name: '하이난', value: '9' },
      { name: '항저우', value: '10' },
    ],
  },
  {
    continent: '2',
    country: '1',
    city: [
      { name: '전체', value: '0' },
      { name: '끄라비', value: '1' },
      { name: '방콕', value: '2' },
      { name: '치앙마이', value: '3' },
      { name: '파타야', value: '4' },
      { name: '푸켓', value: '5' },
    ],
  },
  {
    continent: '2',
    country: '2',
    city: [
      { name: '전체', value: '0' },
      { name: '나트랑', value: '1' },
      { name: '다낭', value: '2' },
      { name: '달랏', value: '3' },
      { name: '푸꾸옥', value: '4' },
      { name: '하노이', value: '5' },
      { name: '호이안', value: '6' },
      { name: '호치민', value: '7' },
    ],
  },
  {
    continent: '2',
    country: '3',
    city: [
      { name: '전체', value: '0' },
      { name: '싱가포르', value: '1' },
    ],
  },
  {
    continent: '2',
    country: '4',
    city: [
      { name: '전체', value: '0' },
      { name: '마닐라', value: '1' },
      { name: '보라카이', value: '2' },
      { name: '보홀', value: '3' },
      { name: '세부', value: '4' },
    ],
  },
  {
    continent: '2',
    country: '5',
    city: [
      { name: '전체', value: '0' },
      { name: '롬복', value: '1' },
      { name: '바탐', value: '2' },
      { name: '발리', value: '3' },
      { name: '자카르타', value: '4' },
    ],
  },
  {
    continent: '2',
    country: '6',
    city: [
      { name: '전체', value: '0' },
      { name: '코타키나발루', value: '1' },
      { name: '쿠알라룸푸르', value: '2' },
      { name: '페낭', value: '3' },
    ],
  },
  {
    continent: '3',
    country: '1',
    city: [
      { name: '전체', value: '0' },
      { name: '뉴 몰든', value: '1' },
      { name: '뉴캐슬', value: '2' },
      { name: '런던', value: '3' },
      { name: '리버풀', value: '4' },
      { name: '멘체스터', value: '5' },
      { name: '모르덴', value: '6' },
      { name: '바스', value: '7' },
      { name: '켄트', value: '8' },
    ],
  },
  {
    continent: '3',
    country: '2',
    city: [
      { name: '전체', value: '0' },
      { name: '그라스', value: '1' },
      { name: '낭트', value: '2' },
      { name: '니스', value: '3' },
      { name: '마르세유', value: '4' },
      { name: '칸', value: '5' },
      { name: '파리', value: '6' },
      { name: '푸아티에', value: '7' },
    ],
  },
  {
    continent: '3',
    country: '3',
    city: [
      { name: '전체', value: '0' },
      { name: '바르셀로나', value: '1' },
      { name: '마드리드', value: '2' },
      { name: '발렌시아', value: '3' },
      { name: '말라가', value: '4' },
      { name: '세비야', value: '5' },
      { name: '그라나다', value: '6' },
      { name: '코르도바', value: '7' },
      { name: '톨레도', value: '8' },
    ],
  },
  {
    continent: '3',
    country: '4',
    city: [
      { name: '전체', value: '0' },
      { name: '베를린', value: '1' },
      { name: '뮌헨', value: '2' },
      { name: '프랑크푸르트', value: '3' },
      { name: '뉘른베르크', value: '4' },
      { name: '드레스덴', value: '5' },
      { name: '밤베르크', value: '6' },
    ],
  },
  {
    continent: '3',
    country: '5',
    city: [
      { name: '전체', value: '0' },
      { name: '베로나', value: '1' },
      { name: '베네치아', value: '2' },
      { name: '볼로냐', value: '3' },
      { name: '로마', value: '4' },
      { name: '나폴리', value: '5' },
      { name: '피렌체', value: '6' },
      { name: '피사', value: '7' },
      { name: '페루자', value: '8' },
    ],
  },
  {
    continent: '4',
    country: '1',
    city: [
      { name: '전체', value: '0' },
      { name: '시드니', value: '1' },
      { name: '골든코스트', value: '2' },
      { name: '멜버른', value: '3' },
      { name: '애들레이드', value: '4' },
      { name: '케언즈', value: '5' },
      { name: '캔버라', value: '6' },
      { name: '다윈', value: '7' },
    ],
  },
  {
    continent: '5',
    country: '1',
    city: [
      { name: '전체', value: '0' },
      { name: '뉴욕', value: '1' },
      { name: '시카고', value: '2' },
      { name: '애틀란타', value: '3' },
      { name: '로스엔젤레스', value: '4' },
      { name: '라스베가스', value: '5' },
      { name: '필라델피아', value: '6' },
      { name: '샌디에이고', value: '7' },
      { name: '샌프란시스코', value: '8' },
      { name: '하와이', value: '9' },
    ],
  },
  {
    continent: '5',
    country: '2',
    city: [
      { name: '전체', value: '0' },
      { name: '밴쿠버', value: '1' },
      { name: '퀘벡', value: '2' },
      { name: '몬트리올', value: '3' },
      { name: '빅토리아', value: '4' },
      { name: '토론토', value: '5' },
    ],
  },
];
