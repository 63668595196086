import {
  Button,
  Checkbox,
  Divider,
  Form,
  Image,
  Input,
  Radio,
  Space,
  Tabs,
  TabsProps,
  Timeline,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { client } from '../axios';
import { useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import 'moment/locale/ko';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { IMAGE_URL } from '../const/imageUrl';

const items: TabsProps['items'] = [
  {
    key: '1',
    label: '1.계정만들기',
  },
  {
    key: '2',
    label: '2.기본정보입력',
  },
  {
    key: '3',
    label: '3.전문가증빙제출',
  },
];

export function History() {
  const navigate = useNavigate();
  const params = useParams();
  const [tab, setTab] = useState('1');
  const [seller, setSeller] = useState<any>({});
  const [cardInfo, setCardInfo] = useState(['', '', '']);

  useEffect(() => {
    client.get(`/seller/history/${params.id}`).then((res) => {
      setSeller(res.data);
      setCardInfo(res.data?.cardInfo?.split('|'));
    });
  }, []);

  const handleApprove = () => {
    if (!window.confirm('전문가 신청을 승인하시겠습니까?')) return;

    client.post(`/seller/approve/${params.id}`).then((res) => {
      alert('전문가 신청이 승인되었습니다.');
      navigate(`/seller/history/${params.id}`);
    });
  };

  const handleDeny = () => {
    if (!window.confirm('전문가 신청을 반려하시겠습니까?')) return;
    const reason = prompt('반려 이유를 입력해주세요.');

    client
      .post(`/seller/deny/${params.id}`, { deny_reason: reason })
      .then((res) => {
        alert('전문가 신청이 반려되었습니다.');
        navigate(`/seller/history/${params.id}`);
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={handleApprove}>
          승인
        </Button>
        <Button danger style={{ marginLeft: 10 }} onClick={handleDeny}>
          반려
        </Button>
      </div>
      <Tabs
        defaultActiveKey='1'
        items={items}
        activeKey={tab}
        onChange={(key) => setTab(key)}
      />
      {tab === '1' && (
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item label='이름'>
            <Input style={{ color: '#000' }} value={seller?.name || ''} />
          </Form.Item>

          <Form.Item label='이메일'>
            <Input style={{ color: '#000' }} value={seller?.email || ''} />
          </Form.Item>

          <Form.Item label='카카오톡ID'>
            <Input style={{ color: '#000' }} value={seller?.socialId || ''} />
          </Form.Item>
        </Form>
      )}

      {tab === '2' && (
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item label='프로필이미지'>
            <Image
              width={150}
              height={150}
              src={`${IMAGE_URL}${seller?.profileFilename}`}
            />
          </Form.Item>
          <Form.Item label='활동명'>
            <Input style={{ color: '#000' }} value={seller?.nickname || ''} />
          </Form.Item>

          <Form.Item label='판매서비스타입'>
            <div style={{ margin: '10px 0' }}>
              <Checkbox
                checked={seller?.serviceType?.includes('1')}
                style={{ color: '#000' }}
              >
                여행 플래닝 (상담 + 1:1 맞춤 여행계획)
              </Checkbox>
            </div>

            <div style={{ margin: '10px 0' }}>
              <Checkbox checked={seller?.serviceType?.includes('2')}>
                가이드투어
              </Checkbox>
            </div>

            <div style={{ margin: '10px 0' }}>
              <Checkbox checked={seller?.serviceType?.includes('3')}>
                스냅촬영
              </Checkbox>
            </div>
          </Form.Item>

          <Form.Item label='자기소개'>
            <TextArea style={{ height: 200 }} value={seller?.intro || ''} />
          </Form.Item>

          <Form.Item label='첫번째 명함정보'>
            <Input value={cardInfo?.length >= 1 ? cardInfo[0] : ''} />
          </Form.Item>

          <Form.Item label='두번째 명함정보'>
            <Input value={cardInfo?.length >= 2 ? cardInfo[1] : ''} />
          </Form.Item>

          <Form.Item label='세번째 명함정보'>
            <Input value={cardInfo?.length >= 3 ? cardInfo[2] : ''} />
          </Form.Item>

          <Form.Item label='연락처'>
            <Input value={seller?.phone || ''} />
          </Form.Item>

          <Form.Item label='상담가능시간 시작'>
            <Input value={`${seller?.startTime}시`} />
          </Form.Item>

          <Form.Item label='상담가능시간 종료'>
            <Input value={`${seller?.endTime}시`} />
          </Form.Item>
          {/* <Option value={'1'}>30분 이하</Option>
              <Option value={'2'}>30분 이상 1시간 이하</Option>
              <Option value={'3'}>1시간 이상</Option> */}
          <Form.Item label='평균 응답 소요시간'>
            <Input
              value={
                seller?.avgResponseTime == '30'
                  ? '30분 이내'
                  : seller?.avgResponseTime == '60'
                  ? '1시간 이내'
                  : ''
              }
            />
          </Form.Item>
        </Form>
      )}

      {tab === '3' && (
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item label='해외거주여부'>
            <Radio.Group value={seller?.isAbroad}>
              <Space direction='vertical'>
                <Radio value={1}>네, 그렇습니다.</Radio>
                <Radio value={0}>아니요, 국내 거주 중입니다.</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          {seller?.isAbroad === 1 && (
            <Form.Item label='체류증명서'>
              <a href={`${IMAGE_URL}${seller?.certificateFilename}`}>
                <Button>증명서 다운로드</Button>
              </a>
            </Form.Item>
          )}

          <Form.Item label='신분증사본'>
            <a href={`${IMAGE_URL}${seller?.idFilename}`}>
              <Button>사본 다운로드</Button>
            </a>
          </Form.Item>

          <Form.Item label='은행명'>
            <Input value={seller?.bank} />
          </Form.Item>

          <Form.Item label='정산계좌'>
            <Input value={seller?.accountNum} />
          </Form.Item>

          <Form.Item label='예금주'>
            <Input value={seller?.accountNum} />
          </Form.Item>

          <Form.Item label='계좌사본'>
            <a href={`${IMAGE_URL}${seller?.accountFilename}`}>
              <Button>사본 다운로드</Button>
            </a>
          </Form.Item>
        </Form>
      )}

      <Divider />

      <div>
        <Title style={{ marginBottom: 20 }} level={4}>
          전문가 신청 히스토리
        </Title>
        <Timeline
          items={
            //   [
            //   {
            //     children: 'Create a services site 2015-09-01',
            //   },
            //   {
            //     children: 'Solve initial network problems 2015-09-01',
            //   },
            //   {
            //     children: 'Technical testing 2015-09-01',
            //   },
            //   {
            //     children: 'Network problems being solved 2015-09-01',
            //   },
            // ]
            // 0: 가입완료, 1: 기본정보입력완료, 2: 전문가증빙제출완료
            seller?.tblSellerHistories?.map((el: any) => {
              return {
                color:
                  el?.status == 2 ? 'red' : el?.status == 1 ? 'green' : 'blue',
                children: `${
                  el?.progressStatus == 0
                    ? '가입완료'
                    : el?.progressStatus == 1
                    ? '기본정보입력완료'
                    : el?.progressStatus == 2
                    ? '전문가증빙제출완료'
                    : '-'
                } | ${
                  el?.status == 1 ? '승인' : el?.status == 2 ? '반려' : '-'
                } ${el?.denyReason ? `| ${el?.denyReason} |` : ''} ${moment(
                  el?.createdAt
                ).format('YYYY-MM-DD HH:mm')}`,
              };
            })
          }
        />
      </div>
    </div>
  );
}
