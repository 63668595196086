import {
  Button,
  Image,
  Pagination,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from 'antd';

import { useEffect, useState } from 'react';
import { useQuery } from '../hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import { client } from '../axios';
import moment from 'moment';
import { continent } from '../db/country';
import { IMAGE_URL } from '../const/imageUrl';
import { fallbackImg } from '../db/fallbackImg';

const { Title } = Typography;

const options = [
  {
    value: 'email',
    label: '이메일',
  },
  {
    value: 'name',
    label: '이름',
  },
  {
    value: 'nickname',
    label: '닉네임',
  },
  {
    value: 'phone',
    label: '연락처',
  },
];

export function Services() {
  const query = useQuery();
  const navigate = useNavigate();

  const page = +query.get('page')! || 1;
  const limit = +query.get('limit')! || 10;

  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<any>(query.get('filter') || '');
  const [searchTerm, setSearchTerm] = useState<string>(
    query.get('search_term') || ''
  );
  const [searchType, setSearchType] = useState<string>(
    query.get('search_type') || 'email'
  );

  const columns: TableColumnsType<any> = [
    {
      title: '썸네일',
      dataIndex: 'service_thumbnail_filename',
      key: 'service_thumbnail_filename',
      render: (text: string) => (
        <Image
          src={`${IMAGE_URL}${text}`}
          style={{ width: 110, height: 110 }}
          fallback={fallbackImg}
        />
      ),
    },
    {
      title: '전문가 닉네임',
      dataIndex: 'seller_nickname',
      key: 'seller_nickname',
    },
    {
      title: '대륙',
      dataIndex: 'continent',
      key: 'continent',
      render: (text: string) =>
        continent.find((el) => el.value == text)?.name || '',
    },
    {
      title: '국가',
      dataIndex: 'country',
      key: 'country',
      render: (text: string) => (text == '0' ? '전체' : text),
    },
    {
      title: '도시',
      dataIndex: 'city',
      key: 'city',
      render: (text: string) => (text == '0' ? '전체' : text),
    },
    {
      title: '상태',
      dataIndex: 'sale_type',
      key: 'sale_type',
      render: (text: string) => {
        if (text == '6') {
          return <Tag color='purple'>휴면해제요청</Tag>;
        }
        return text == '0' ? (
          <Tag color='red'>작성중</Tag>
        ) : text == '1' ? (
          <Tag color='gold'>심사중</Tag>
        ) : text == '2' ? (
          <Tag color='blue'>판매중</Tag>
        ) : text == '3' ? (
          <Tag color='blue'>숨김</Tag>
        ) : text == '4' ? (
          <div style={{ margin: '0 auto' }}>
            <Tag color='blue'>반려</Tag>
          </div>
        ) : text == '5' ? (
          <div style={{ margin: '0 auto' }}>
            <Tag color='purple'>휴면</Tag>
          </div>
        ) : text == '6' ? (
          <div style={{ margin: '0 auto' }}>
            <Tag color='gold'>재심사중</Tag>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: '최종수정일시',
      dataIndex: 'service_updated_time',
      key: 'service_updated_time',
      render: (text: string) => moment(text).format('YYYY.MM.DD HH:mm'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (row: any) => {
        return (
          <Button
            type='primary'
            onClick={() => navigate(`/seller/service/${row.service_uid}`)}
          >
            상세보기
          </Button>
        );
      },
    },
  ];

  const getList = (
    _page: number | string,
    _limit: number | string,
    _searchType: string,
    _searchTerm: string,
    _filter: string
  ) => {
    client
      .get('/service/admin', {
        params: {
          page: _page,
          limit: _limit,
          // search_type: _searchType,
          // search_term: _searchTerm.trim(),
          // filter: _filter,
        },
      })
      .then((res) => {
        setList(res.data.list);
        setTotalCount(res.data.count || 0);
      })
      .catch((err) => console.error(err));
  };

  const handleSearch = (value: string) => {
    let url = `/service/admin?page=${page}&limit=${limit}`;
    url += `&search_term=${value}&search_type=${searchType}`;
    navigate(url);
  };

  useEffect(() => {
    getList(page, limit, searchType, searchTerm, filter);
  }, [page, limit, filter, query.get('search_term'), query.get('search_type')]);

  return (
    <div>
      <Title level={3}>상품 관리</Title>
      {/* <Space.Compact style={{ float: 'right', marginBottom: 10 }}>
        <Select
          defaultValue={'expert_nickname'}
          value={searchType}
          onChange={(value) => setSearchType(value)}
          options={options}
        />
        <Search
          placeholder='검색어를 입력해주세요.'
          onChange={(e) => setSearchTerm(e.target.value)}
          onSearch={handleSearch}
        />
      </Space.Compact> */}
      <Table
        onChange={(_, filter) => {
          if (filter?.type) {
            setFilter(filter.type[0]);
          } else {
            setFilter('');
          }
        }}
        dataSource={list}
        pagination={false}
        columns={columns}
        rowKey={(row: any) => row.id}
      />
      <Pagination
        style={{ marginTop: 20 }}
        defaultCurrent={page}
        pageSize={limit}
        total={totalCount}
        onChange={(_page, _pageSize) => {
          let url = `/seller/service?page=${_page}&limit=${_pageSize}`;
          if (searchTerm && searchType) {
            url += `?search_term=${searchTerm}&search_type=${searchType}`;
          }

          navigate(url);
        }}
      />
    </div>
  );
}
