import { useCallback } from 'react';
import { io, Socket } from 'socket.io-client';

const backUrl = 'https://api.travelmarket.site';
// const backUrl = 'http://localhost:3001';
// const backUrl = 'http://192.168.2.57:3001';
// process.env.NODE_ENV === 'production'
//   ? 'http://ec2-54-180-88-38.ap-northeast-2.compute.amazonaws.com:3001'
//   : 'http://localhost:3001';

const sockets: { [key: string]: Socket } = {};
const useSocket = (workspace?: string): [Socket | undefined, () => void] => {
  const disconnect = useCallback(() => {
    if (workspace && sockets[workspace]) {
      sockets[workspace].disconnect();
      delete sockets[workspace];
    }
  }, [workspace]);
  if (!workspace) {
    return [undefined, disconnect];
  }

  if (!sockets[workspace]) {
    sockets[workspace] = io(`${backUrl}/root`, {
      transports: ['websocket'],
      query: { type: 'ADMIN' },
    });
    sockets[workspace].on('connect_error', (err) => {
      console.error(err);
      console.log(`connect_error due to ${err.message}`);
    });
  }

  return [sockets[workspace], disconnect];
};

export default useSocket;
