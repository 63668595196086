import React, { useEffect } from 'react';
import { Button, Layout as AntLayout, Menu } from 'antd';
import { useAtom } from 'jotai';
import menuKeysAtoms from './stores/menuKeysAtoms';
import { useNavigate } from 'react-router-dom';
import { client } from './axios';

const { Header, Content, Footer, Sider } = AntLayout;

const menuItems = [
  // { label: '사용자 관리', key: '/users' },
  { label: '채팅방 관리', key: '/rooms' },
  { label: '전문가신청내역 관리', key: '/seller/history' },
  { label: '상품 관리', key: '/seller/service' },
];

export const Layout = (props: any) => {
  const [keys, setKeys] = React.useState('');
  const [menuKeys, setMenuKeys] = useAtom(menuKeysAtoms);
  const navigate = useNavigate();

  return (
    <AntLayout {...props} style={{ minHeight: '100vh' }}>
      <Sider
        // 모바일에서 보이게 하기 위해 브레이크 포인트 제거
        // breakpoint='lg'
        collapsedWidth="0"
        theme="light"
        style={{ minHeight: '100vh' }}
      >
        <div
          className="logo"
          style={{
            // height: '64px',
            display: 'flex',
            justifyContent: 'flex-start',
            // paddingLeft: '20px',
          }}
        >
          <img
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
            src="/logo.png"
            width={150}
          />
        </div>

        {/* @ts-ignore */}
        <Menu
          theme="light"
          mode="vertical"
          selectedKeys={menuKeys.keys}
          openKeys={menuKeys.openKeys}
          onOpenChange={(keys) => {
            setMenuKeys({
              ...menuKeys,
              openKeys: keys,
            });
          }}
          onSelect={({ key }) => {
            setMenuKeys({
              ...menuKeys,
              keys: [key],
            });
            navigate(key);
          }}
          items={menuItems}
        ></Menu>
      </Sider>
      <AntLayout {...props}>
        <Header
          style={{
            background: '#fff',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            type={'link'}
            danger
            onClick={() => {
              localStorage.removeItem('token');
              localStorage.removeItem('user_id');
              client.defaults.headers.common = {
                Authorization: '',
              };

              window.location.href = '/login';
            }}
          >
            로그아웃
          </Button>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Copyright © Moglet. All rights reserved.
        </Footer>
      </AntLayout>
    </AntLayout>
  );
};
