import React from 'react';
import type { FormProps } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import { client } from '../axios';
import { useNavigate } from 'react-router-dom';

type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};

export function Login() {
  const navigate = useNavigate();
  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    console.log('Success:', values);
    client
      .post('/auth/sign-in/admin', {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        client.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${res.data.accessToken}`;

        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('user_id', res.data.user.id);

        window.location.href = '/';
      })
      .catch((error) => {
        const msg = error.response.data?.message;
        if (msg) {
          alert(msg);
        }
        console.error(error);
      });
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo
  ) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div
      style={{
        marginTop: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: 30,
        }}
      >
        <img width={200} src='/logo.png' />
        <div style={{ fontWeight: 'bold', fontSize: 20 }}>관리자 페이지</div>
      </div>
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
      >
        <Form.Item<FieldType>
          label='아이디'
          name='email'
          rules={[{ required: true, message: '아이디 입력해주세요.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label='비밀번호'
          name='password'
          rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type='primary' htmlType='submit'>
            로그인
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
