import "src/styles/chat.style.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/chat.style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VVTY+bMBC951eMVFXaPTgyJCGJc+mlP8TgIfEusZExC7tV/nuF+XIIaKtuKy5oGM+8ee95WJcvpjx+UPi1ArigPF8sg4RnyVNA6dsFCBxoXj+fVgCxNgINMVzIsmCwy+smeuXmLBUDCry0uomkGdZESIOJlVoxSHRWXlXzRcgiz/g7cymn1W21brsHrvvkK8BLWViZvpNEK4vKMihyniCJ0VaIrmDOhZDqzCCgLRqeybMi0uK1YJCgsmg86LG2Vl8ZhHkNhc6kgG8/d83jcnjyeja6VIIkOtOGwbc0TT2UoUO5lPZX+L8At9dqe2gnH6igeQ2BE2dAvnHIU60sKeQHMgii9pALVV2liFLvzNadeSA414VsZU1ljeI0XzfD1DKgzatpi9N2rnYgOt97GNzqfEmkPxFy1qf6DU2a6YrBRQqBc36cUWzUZEmqz1yz+8Q17racTQMsaNHXDZmO9GHae4Un6katupM6BltJlFbo5i9tJhX2Sszgoekm9Qgcj04IDcI7x5FW7N4gvS831LNY545N5w6pkNznDcPs51fBdKcYXRGDb2gKfBDH5aIS43Ya/BLMgNre9T+4/tVFWiTuujLIDZLK8Nx3kQswiA3yV1JpI2a30QzLYXyMMGk+TnywtF1rUklhLwx29PsDecE69LAf57n7d+Tw/0wOBs3jk0Pv9sJXyIkddou1JY4Q/xLPeLTjpt1E9C42LJ8u3GM9hsfwuGla/riikByKxCAq4ErA01WqHus+OuT1s4Oz9v++AF1CRLvK423a74ZQfztnd98DyP4a3sZuYddtqB0+pGy6FI+Y8DFru5zl/ygKK5PX9x689we4LVA1yrqP9gtUDSLTVnm/IY8LnZUWTz6pY6LTlE5m2XV1J/RFA32330OWSuAqCQAA\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var chatHeader = 'ujru9z2';
export var chatHeaderTitle = 'ujru9z3';
export var container = 'ujru9z0';
export var dateLine = 'ujru9zb';
export var header = 'ujru9z1';
export var inputContainer = 'ujru9z4';
export var messageContainer = 'ujru9z5';
export var messageFromMe = 'ujru9z8';
export var messageFromMeContainer = 'ujru9z7';
export var messageToMe = 'ujru9za';
export var messageToMeContainer = 'ujru9z9';
export var textarea = 'ujru9z6';