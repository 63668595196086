import { Form, Input } from 'antd';
export default function PaymentResultInput({
  title,
  type,
  value,
  onChange,
}: PaymentResultInputProps) {
  return (
    <Form.Item label={title}>
      <Input
        type={type === 0 ? 'text' : 'number'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{ color: '#000' }}
      />
    </Form.Item>
  );
}
interface PaymentResultInputProps {
  title: string;
  type: number;
  value: string | number;
  onChange: (param: any) => void;
}
